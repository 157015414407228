import '../../sass/style.scss';

import React from 'react'

import {Action, actionForKey, actionIsDrop} from './business/input'
import {playerController} from './business/playerController'
import {useInterval} from './hooks/useInterval'
import {useDropTime} from './hooks/useDropTime'

export default function GameController(props) {
    const {
          board,
          gameStats,
          player,
          setGameOver,
          setPlayer
        } = props

    const [dropTime, pauseDropTime, resumeDropTime] = useDropTime({
            gameStats
          });

    useInterval(() => {
            handleInput({ action: Action.SlowDrop });
          }, dropTime);

          const onKeyUp = ({ code }) => {
            const action = actionForKey(code);
            if (actionIsDrop(action)) resumeDropTime();
          };
        
          const onKeyDown = ({ code }) => {
            const action = actionForKey(code);
        
            if (action === Action.Pause) {
              if (dropTime) {
                pauseDropTime();
              } else {
                resumeDropTime();
              }
            } else if (action === Action.Quit) {
              setGameOver(true);
            } else {
              if (actionIsDrop(action)) pauseDropTime();
              if (!dropTime) {
                return;
              }
              handleInput({ action });
            }
          };

    const handleInput = ({action}) => {
        playerController({
            action,
            board,
            player,
            setPlayer,
            setGameOver
        });
    }
              
    
  return (
    <input 
    type="text"
     className='gameController'
     onKeyDown={onKeyDown}
     onKeyUp={onKeyUp}
     autoFocus
      />
  )
}


// import { Action, actionForKey, actionIsDrop } from "/src/business/Input";
// import { playerController } from "/src/business/PlayerController";

// import { useDropTime } from "/src/hooks/useDropTime";
// import { useInterval } from "/src/hooks/useInterval";

// const GameController = ({
//   board,
//   gameStats,
//   player,
//   setGameOver,
//   setPlayer
// }) => {
//   const [dropTime, pauseDropTime, resumeDropTime] = useDropTime({
//     gameStats
//   });

//   useInterval(() => {
//     handleInput({ action: Action.SlowDrop });
//   }, dropTime);

//   const onKeyUp = ({ code }) => {
//     const action = actionForKey(code);
//     if (actionIsDrop(action)) resumeDropTime();
//   };

//   const onKeyDown = ({ code }) => {
//     const action = actionForKey(code);

//     if (action === Action.Pause) {
//       if (dropTime) {
//         pauseDropTime();
//       } else {
//         resumeDropTime();
//       }
//     } else if (action === Action.Quit) {
//       setGameOver(true);
//     } else {
//       if (actionIsDrop(action)) pauseDropTime();
//       if (!dropTime) {
//         return;
//       }
//       handleInput({ action });
//     }
//   };

//   const handleInput = ({ action }) => {
//     playerController({
//       action,
//       board,
//       player,
//       setPlayer,
//       setGameOver
//     });
//   };

//   return (
//     <input
//       className="GameController"
//       type="text"
//       onKeyDown={onKeyDown}
//       onKeyUp={onKeyUp}
//       autoFocus
//     />
//   );
// };

// export default GameController;
