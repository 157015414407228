import React from 'react'

export default function Background() {
  return (<>
    <div className="stars"></div>
    <div className="twinkling"></div> 
    
       </>
  )
}
