import { useCallback } from "react";
import { useState } from "react"

export const useGameOver =() =>{
    const [gameOver, setGameOver] = useState(true);

    const resetGameOver = useCallback(() =>{
        setGameOver(false);
    }, []);

    return [gameOver, setGameOver, resetGameOver]


}